<template>

</template>

<script>
import $wxJs from '@/util/jweixin-module/lib/index.js';
import { GET_TOKEN } from '@/util/storage';

export default {
  name: 'bindMiniUser',
  mounted() {
    const token = GET_TOKEN();
    $wxJs.miniProgram.postMessage({ data: token });
    $wxJs.miniProgram.navigateBack();
  }
};
</script>

<style scoped>

</style>
